/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: normal;
    src: local('Comfortaa'), url('Comfortaa_Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Comfortaa Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Comfortaa Thin'), url('Comfortaa_Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Comfortaa Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Comfortaa Bold'), url('Comfortaa_Bold.woff') format('woff');
    }