/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Lato Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Regular'), url('Lato-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Italic'), url('Lato-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Hairline';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Hairline'), url('Lato-Hairline.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Hairline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Hairline Italic'), url('Lato-HairlineItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Light';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Light'), url('Lato-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Light Italic'), url('Lato-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Bold'), url('Lato-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Bold Italic'), url('Lato-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Black';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Black'), url('Lato-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Lato Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Lato Black Italic'), url('Lato-BlackItalic.woff') format('woff');
    }