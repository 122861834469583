/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Aileron Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Regular'), url('Aileron-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Italic'), url('Aileron-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Thin'), url('Aileron-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron UltraLight'), url('Aileron-UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Thin Italic'), url('Aileron-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron UltraLight Italic'), url('Aileron-UltraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Light';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Light'), url('Aileron-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Light Italic'), url('Aileron-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron SemiBold'), url('Aileron-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron SemiBold Italic'), url('Aileron-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Bold'), url('Aileron-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Bold Italic'), url('Aileron-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Black';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Black'), url('Aileron-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Heavy'), url('Aileron-Heavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Black Italic'), url('Aileron-BlackItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Aileron Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Aileron Heavy Italic'), url('Aileron-HeavyItalic.woff') format('woff');
    }