/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Animosa Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Animosa Regular'), url('Animosa-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Animosa Extra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Animosa Extra Light'), url('Animosa-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Animosa Light';
    font-style: normal;
    font-weight: normal;
    src: local('Animosa Light'), url('Animosa-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Animosa Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Animosa Bold'), url('Animosa-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Animosa Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Animosa Extra Bold'), url('Animosa-ExtraBold.woff') format('woff');
    }