/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Futura PT Book';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Book'), url('FuturaCyrillicBook.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura PT Light';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Light'), url('FuturaCyrillicLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura PT Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Medium'), url('FuturaCyrillicMedium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura PT Demi';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Demi'), url('FuturaCyrillicDemi.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura PT Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Heavy'), url('FuturaCyrillicHeavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura PT Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Bold'), url('FuturaCyrillicBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Futura PT Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Extra Bold'), url('FuturaCyrillicExtraBold.woff') format('woff');
    }